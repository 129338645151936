import React from 'react';
import PropTypes from 'prop-types';
import styled, { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { hostingTheme } from 'common/src/theme/hosting';
import {
  GlobalStyle,
  ContentWrapper,
} from '../../containers/Hosting/hosting.style';
import { ResetCSS } from 'common/src/assets/css/style';
import Navbar from '../../containers/Hosting/Navbar';
import Footer from '../../containers/Hosting/Footer';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { ParallaxProvider } from 'react-scroll-parallax';
import SEO from '../../components/seo';
import TopRibbon from '../../containers/Hosting/TopRibbon';

import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';

import { useStaticQuery, graphql, Link } from 'gatsby';
import MiscWrapper from './misc.style';

import Ctasection from '../../containers/Hosting/Ctasection/Casestudy';
import backgroundImage from '../../../../common/src/assets/image/hosting/pocketresources/common_mortgage_background.png';
import rightImage from '../../../../common/src/assets/image/hosting/pocketresources/loan_processing.png';
import leftImage from '../../../../common/src/assets/image/hosting/pocketresources/what-really-causes-inefficiencies-in-mortgage-processing.jpg';

import downloadFile from '../../contents/pdfs/Infographic_What_Really_Causes_Inefficiencies_in_Mortgage_Processing.pdf';
import { width } from 'styled-system';

const MiscThanksSection1 = ({
  name,
  sectionWrapper,
  row,
  col1,
  col2,
  title,
  subTitle,
  data,
  mainTitleHolder,
  subTitleHolder,
  mainfont,
  lastTextFont,
}) => {
  return (
    <ThemeProvider theme={hostingTheme}>
      <ParallaxProvider>
        <SEO
          title="Infographic - What Really Causes Inefficiencies in Mortgage Processing?"
          description="Rapid Acceleration Partners"
        />
        <ResetCSS />
        <GlobalStyle />
        <TopRibbon />
        <ContentWrapper className="MiscThanksPage">
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            releasedClass="sticky-nav-inactive"
          >
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <PageWrapper>
            <div className="backgroundHolder">
              <div className="title">
                <Container fullWidth>
                  <Box {...row}>
                    <Box {...mainTitleHolder}>
                      <Heading
                        {...title}
                        content="What Really Causes Inefficiencies in Mortgage Processing?"
                      />
                    </Box>
                  </Box>
                </Container>
                <div className="subtitle_background">
                  <Container fullWidth>
                    <Box {...row}>
                      <Box {...subTitleHolder}>
                        <Heading
                          {...subTitle}
                          content="The lending and borrowing process involves a lot of documentation and data collection, which takes time. Tons of unstructured and unverified data is the root cause of inefficiencies which can lead to low levels of customer satisfaction. IPA can help you improve the #digitalcustomerexperience by reducing the inefficiencies and quickening the repetitive and document intensive process."
                        />
                      </Box>
                    </Box>
                  </Container>
                </div>
              </div>
            </div>
            <div className="section2">
              <Container fullWidth>
                <Box {...row}>
                  <img
                    src={leftImage}
                    alt="What Really Causes Inefficiencies in Mortgage Processing"
                  />
                </Box>
              </Container>
            </div>
            <div className="section3">
              <Container>
                {/* <div className="fadeEffect"></div>
                <Text
                  {...mainfont}
                  dangerouslySetInnerHTML={{
                    __html:
                      "According to a Canon study, the costs from compliance and processing errors range from <span class='redText'> $100,000 </span> to <span class='redText'>$1 </span> million and above.",
                  }}
                />
                <div className="fadeEffect"></div> */}

                <div className="lastTextSection">
                  <Text
                    {...lastTextFont}
                    content="Minimize loan processing incidents and establish a friction-less mortgage processing centre of excellence with
Hyperautomation. Discover how hyperautomation could help your company."
                  />
                </div>
              </Container>
              {/* <Box {...row}>
                <Link to="bookademo" className="discoveryButton">
                  Book a Discovery Call <i className="fa fa-arrow-right"></i>
                </Link>
              </Box> */}
            </div>
            <Container>
              <div className="customRow">
                <a className="discoveryButton" href={downloadFile}>
                  <span className="btn-text">Download Infographic here</span>
                </a>
              </div>
            </Container>
            <Ctasection content="Accelerate Loan Processing Cycles and Achieve Peak Efficiency In Mortgage Processing" />
          </PageWrapper>
          <Footer />
        </ContentWrapper>
      </ParallaxProvider>
    </ThemeProvider>
  );
};

MiscThanksSection1.propTypes = {
  sectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  col2: PropTypes.object,
  title: PropTypes.object,
};

MiscThanksSection1.defaultProps = {
  sectionWrapper: {
    as: 'div',
    id: 'misc_thanks',
    minHeight: '400px',
    flexBox: true,
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    ml: '-15px',
    mr: '-15px',
  },
  col1: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49.99%', '49.99%'],
    className: ['noline', 'noline', 'line', 'line', 'line'],
  },
  col2: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '100%', '49%', '49%'],
  },
  mainTitleHolder: { width: ['100%', '100%', '80%', '70%', '70%'] },
  subTitleHolder: { width: ['100%', '100%', '80%', '80%', '80%'] },
  title: {
    fontSize: ['30px', '30px', '35px', '40px', '40px'],
    fontWeight: '700',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '25px',
    pt: '0',
    textAlign: 'center',
  },
  subTitle: {
    fontSize: ['20px', '20px', '22px', '22px', '22px'],
    fontWeight: '400',
    color: '#ffffff',
    letterSpacing: '0.015em',
    mb: '0px',
    pt: '0',
    textAlign: 'center',
  },
  mainfont: {
    fontSize: ['20px', '20px', '22px', '24px', '24px'],
    mx: ['0px', '0px', '100px', '100px', '100px'],
    lineHeight: '1.5em',
    fontWeight: '900',
    color: '#4d4d4f',
    letterSpacing: '0.015em',
    mb: '0px',
    pt: '0',
    textAlign: 'center',
  },
  lastTextFont: {
    fontSize: ['16px', '16px', '16px', '16px', '16px'],
    mx: ['0px', '0px', '100px', '100px', '100px'],
    lineHeight: '1.5em',
    fontWeight: '400',
    color: '#4d4d4f',
    letterSpacing: '0.015em',
    mb: '0px',
    pt: '0',
    textAlign: 'center',
  },
};

export default MiscThanksSection1;

const PageWrapper = styled.section`
  @import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

  *:not(.fa) {
    font-family: 'Lato', sans-serif;
  }
  .backgroundHolder {
    margin-top: 125px;
    background: url(${backgroundImage});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
  }
  .subtitle_background {
    background-color: rgba(255, 0, 0, 0.75);
    padding: 25px 0px 25px 0px;
  }
  img {
    width: 100%;
  }
  .section2 {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .line {
    border-right: 1px solid #4d4d4f;
  }
  .fadeEffect {
    display: block;
    border: none;
    color: white;
    height: 1px;
    background: black;
    background: -webkit-gradient(
      radial,
      50% 50%,
      0,
      50% 50%,
      350,
      from(#ff3333),
      to(#fff)
    );
    margin-bottom: 15px;
    margin-top: 15px;
  }
  .redText {
    color: red;
  }
  .discoveryButton {
    background: #ed1c24;
    color: #fff;
    padding: 10px;
    font-weight: 700;
    border-radius: 10px;
    margin-top: 20px;
    box-shadow: inset 0px 0px 10px 0px #000;
  }
  .customRow {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
  }
`;
